<template>
    <b-modal id="mark-inquiry-as-done" size="lg" title="Mark As Done" ref="modal" @ok="handleOk" @show="onReset"
        ok-title="Mark As Done" :no-close-on-backdrop="true">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <b-form-group class="px-3">
            <div class="mb-4">Proceed marking this inquiry as <b-badge variant="success">DONE</b-badge>?
            </div>
            <div class="mb-2" style="font-weight: 700">INQUIRY DETAILS</div>
            <b-row>
                <b-col sm="2">
                    <div class="form-field">Company</div>
                    <div class="form-field">Name</div>
                    <div class="form-field">Date Created</div>
                    <div class="form-field">Message</div>
                </b-col>
                <b-col sm="10">
                    <div class="form-value">{{ getValue(selInquiry, 'companyName') }}</div>
                    <div class="form-value">{{ getValue(selInquiry, 'fullName') }}</div>
                    <div class="form-value">{{ getValue(selInquiry, 'dateCreated') | toDateTimeString }}</div>
                    <truncate class="form-value" collapsed-text-class="collapsed" clamp="Show More" :length="200"
                        less="Show Less" type="html" :text="getMessage(selInquiry)" />
                </b-col>
            </b-row>
        </b-form-group>
    </b-modal>
</template>

<script>
// API & DAO
import inquiryDAO from '@/database/inquiries';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';

export default {
    name: 'mark-inquiry-as-done',
    components: {
        Loading,
        truncate
    },
    data() {
        return {
            selInquiry: {},
            isLoading: false,
            loggedUser: this.$store.getters.loggedUser,
        }
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
    },
    methods: {
        async handleOk(evt) {
            // Prevent modal from closing
            evt.preventDefault();

            // show loading indicator
            try {

                this.selInquiry.updatedBy = this.loggedUser.id;
                this.selInquiry.dateUpdated = DateUtil.getCurrentTimestamp();

                let result = await inquiryDAO.markInquiryAsDone(this.selInquiry);
                if (result.isSuccess) {
                    this.$toaster.success('Inquiry Marked as Done successfully.');
                    this.$refs.modal.hide();
                    EventBus.$emit('onCloseSelInquiry', this.selInquiry);
                } else {
                    this.$toaster.error('There is an error marking inquiry as DONE. Please try again.');
                }

            } catch (error) {
                console.error(error);
                this.$toaster.error('There is an error marking inquiry as DONE. Please try again.');
            }

            // hide loading indicator
            this.isLoading = false;
        },
        getValue(item, fieldName) {
            let selInquiry = item ? item : {};
            return selInquiry[fieldName] ? selInquiry[fieldName] : '-';
        },
        getMessage(message) {
            let inquiry = message ? message : {};
            let inquiryMessage = inquiry.message ? inquiry.message : '-';
            return this.breakMessage(inquiryMessage, 80);
        },
        breakMessage(message, length) {
            return message.length > length ? message.match(new RegExp(`.{1,${length}}`, 'g')).join('<br>') : message;
        },
        onReset() {
            // init form
            let inquiry = this.$store.getters.currInquiry;
            let inquiryJSON = JSON.stringify(inquiry);
            this.selInquiry = JSON.parse(inquiryJSON);
        },
    }
}
</script>

<style scoped>
.form-field {
    color: #4A4A4A;
    font-weight: bold;
    font-size: 14px;
}

.form-value {
    color: #4A4A4A;
    font-size: 14px;
}
</style>