import { db } from '@/config/firebase';

async function getInquiries(filter) {
    let inquiriesObj = {};

    let query = db.collection('inquiries');

    if (filter.fromTimestamp && filter.fromTimestamp > 0) {
        query = query.where("dateCreated", ">=", filter.fromTimestamp);
    }
    if (filter.toTimestamp && filter.toTimestamp > 0) {
        query = query.where("dateCreated", "<=", filter.toTimestamp);
    }

    query = query.orderBy("dateCreated", "desc");

    const querySnapshots = await query.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let inquiryObj = doc.data();
        inquiriesObj[id] = {
            id: id,
            ...inquiryObj
        }
    });

    return inquiriesObj;
}

async function markInquiryAsDone(form) {
    let isSuccess = false;
    try {
        await db.collection("inquiries").doc(form.id).set({ ...form, status: "DONE" }, { merge: true });
        isSuccess = true;

        return {
            isSuccess: isSuccess,
        };
    } catch (error) {
        isSuccess = false;

        return {
            isSuccess: isSuccess,
            error: error
        };
    }
}



export default {
    getInquiries,
    markInquiryAsDone
}